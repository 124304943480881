#checkbox2 {
    display: none;
}

.toggle2 {
    position: relative;
    width: 60px;
    height: 60px;
    cursor: pointer;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    gap: 12px;
    transition-duration: .9s;
}

.bars {
    width: 60%;
    height: 1px;
    background-color: black;
    border-radius: 6px;
}

#bar5 {
    transition-duration: .6s;
}


#checkbox2:checked+.toggle2 .bars {
    position: absolute;
    transition-duration: .5s;
}

#checkbox2:checked+.toggle2 #bar5 {
    transform: scaleX(0);
    transition-duration: .5s;
}

#checkbox2:checked+.toggle2 #bar4 {
    transform: rotate(45deg);
    transition-duration: .5s;
}

#checkbox2:checked+.toggle2 #bar6 {
    transform: rotate(-45deg);
    transition-duration: .5s;
}

#checkbox2:checked+.toggle2 {
    transition-duration: .5s;
    transform: rotate(180deg);
    transform: translate3d(260px, 0, 0);
    transition: 0.5s cubic-bezier(0.685, 0.0473, 0.346, 1);
}