.content {
    width: 100%;
    max-height: 90vh;
    overflow: auto;
    padding: 25px 30px 30px 280px;
    transition: 0.5s cubic-bezier(0.685, 0.0473, 0.346, 1);
}

@media screen and (max-width:991px) {
    .content {
        padding: 0px 30px 30px 30px;
        transition: 0.5s cubic-bezier(0.685, 0.0473, 0.346, 1);
    }

    .nav1_open {
        transition: 0.5s cubic-bezier(0.685, 0.0473, 0.346, 1);
        transform: translate3d(0, 0, 0);
    }

    .nav1_open.content {
        width: calc(100% - 270px);
        overflow-x: scroll;
    }
}