@import url('https://fonts.googleapis.com/css2?family=Inter&display=swap');

* {
    box-sizing: border-box;
    font-family: "Inter" !important
}

.offcanvas {
    max-width: 90% !important;
    position: fixed;
    z-index: 1200 !important;
}



.nav {
    background-color: #ffff;
    color: #1f4063;
    font-size: 20;
    font-weight: bold;
    margin: 0;
    padding: 0;
    font-size: 1em;
}

.nav a,
.nav p {
    color: #1f4063;
}

.nav,
.nav .div {
    padding: 0.4em 1em;
    display: flex;
    flex-direction: row;
    align-items: center;
    cursor: pointer;
}

.nav .div .p {
    padding-left: 0.5em;
}

.compte {
    display: flex;
    align-items: center;
    justify-content: space-evenly;
    margin-left: auto;
}

.dropdown {
    z-index: 1200 !important;
}