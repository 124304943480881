@import url('https://fonts.googleapis.com/css2?family=Roboto&display=swap');

* {
  box-sizing: border-box;
  font-family: "Roboto" !important;
  margin: 0;

}

@font-face {
  font-family: 'Rockwell';
  src: url('./assets/fonts/ROCK.TTF') format('truetype');
  /* Adjust the file name and format as necessary */
  font-weight: normal;
  /* Adjust if you have different weights */
  font-style: normal;
  /* Adjust if you have italic styles */
}

@font-face {
  font-family: 'Rockwell';
  src: url('./assets/fonts/Rockwell-Bold.ttf') format('truetype');
  /* Adjust the file name and format as necessary */
  font-weight: bold;
  /* Adjust if you have different weights */
  font-style: normal;
  /* Adjust if you have italic styles */
}

@font-face {
  font-family: "Rounded Elegance";
  src: url("./assets/fonts/Rounded_Elegance.ttf") format('truetype');

}

@font-face {
  font-family: "Sakkal Majalla";
  src: url("./assets/fonts/majalla.ttf") format('truetype');
}

@font-face {
  font-family: "Sakkal Majalla";
  src: url("./assets/fonts/majallab.ttf") format('truetype');
  font-weight: bold;
}

body {
  background-color: #F8F8F7
    /* #EAF0FF */
    /*#ffd31a */
    !important;
}

img {
  max-width: 100%;
}

.quill .ql-toolbar {
  position: sticky;
  top: 0;
  z-index: 100;
  background: rgba(255, 255, 255, 1)
}

input {
  border: 1px solid rgba(126, 147, 202, 0.5) !important;
  font-size: 1em !important;
  padding: 0.5em !important;
  font-weight: 500;
}

button,
input[type="submit"] {
  background-color: #2563FF;
  color: white;
  font-size: 1em;
  padding: 0.7em;
  font-weight: bold;
  width: 20%;
  margin-left: auto;
}

h2,
h3,
h4,
h5 {
  color: #002C99 !important;
}



input:focus {
  outline: none;
}


input[type="number"]::-webkit-inner-spin-button,
input[type="number"]::-webkit-outer-spin-button {
  -webkit-appearance: none;
  margin: 0;
  /* Optional: You can adjust margin or padding as needed */
}

.Card .CardHeader *,
.Card Table * {
  color: rgba(34, 42, 66, 0.7) !important;
}

.CardHeader {
  background-color: white !important;
  border: none !important;
}

.Card h4,
.Card td {
  font-weight: 100 !important;
  text-transform: capitalize;
}

.Card td {
  font-size: 0.875rem;
  line-height: 1.5;
  padding: 12px 7px;
}

.Card th {
  font-size: 12px;
  text-transform: uppercase;
}

.pac-container {
  z-index: 2000 !important;
  /* Bootstrap modal z-index is 1050 */
}

.Card {
  margin-bottom: 30px;
}

.dropdow,
.dropdow * {
  z-index: 1021 !important;
}

.platformContent {}