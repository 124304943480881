.Card * {
    color: rgba(34, 42, 66, 0.7) !important;
}

.FromBox {
    background-color: white;
    border: 1px solid rgba(0, 0, 0, 0.125);
    border-radius: 1rem;
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.2);
    padding: 2em;
    padding-bottom: 0;
    display: flex;
}

.CardHeader {
    background-color: white;
    border: none;
}

.Card h4,
.Card td {
    font-weight: 100 !important;
    text-transform: capitalize;
}

.Card td {
    font-size: 0.875rem;
    line-height: 1.5;
    padding: 12px 7px !important;
}

.Card th {
    font-size: 12px;
    text-transform: uppercase;
}

.Card {
    margin: 30px;
}