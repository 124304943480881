.container {
    height: calc(100vh - 80px);
    max-width: 100% !important;
}

.sidebar {
    height: 100%;
    max-height: calc(100vh - 80px);
    width: 100%;
    overflow: auto;
    padding: 10px;
    background-color: white;
}

.card:hover {
    background-color: #F2F2F2;
    cursor: pointer;
}