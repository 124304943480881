.email_input {
    transition: border-color 0.3s ease;
}

.shake {
    animation: shake 0.3s ease-in-out;
    border-color: red;
}

@keyframes shake {
    0% {
        transform: translateX(0);
    }

    25% {
        transform: translateX(-5px);
    }

    50% {
        transform: translateX(5px);
    }

    75% {
        transform: translateX(-5px);
    }

    100% {
        transform: translateX(0);
    }
}