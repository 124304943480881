.nav1 {
    background: linear-gradient(0deg, #1f406d 0%, #3f5064 100%);
    height: calc(100vh - 90px);
    width: 230px;
    position: fixed;
    top: 0;
    left: 0;
    z-index: 1;
    display: block;
    margin-top: 80px;
    margin-left: 20px;
    border-radius: 5px;
    transition: 0.5s cubic-bezier(0.685, 0.0473, 0.346, 1);
}

.nav1 ul li a {
    margin: 10px 15px 0 !important;
    padding: 10px 8px;
    color: white !important;
    align-items: center;
    font-weight: 100 !important;
    text-transform: uppercase;
    font-size: 10px;
}

.nav1 ul li svg {
    margin-right: 12px;
}

@media screen and (max-width:991px) {
    .nav1 {
        position: fixed;
        display: block;
        top: 0;
        height: 100%;
        width: 260px;
        right: auto;
        left: 0;
        margin: 0;
        border-radius: 0;
        z-index: 1100;
        visibility: visible;
        overflow-y: visible;
        padding: 0;
        transition: 0.5s cubic-bezier(0.685, 0.0473, 0.346, 1);
        transform: translate3d(-260px, 0, 0);
    }

    .nav1_open {
        transform: translate3d(0px, 0, 0);
        transition: 0.5s cubic-bezier(0.685, 0.0473, 0.346, 1);
    }

    .togglebox {
        margin-left: 20px;
    }
}