@import url('https://fonts.googleapis.com/css2?family=Inter&display=swap');

.FromBox {
  background-color: white;
  border: 1px solid rgba(0, 0, 0, 0.125);
  border-radius: 1rem;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.2);
  padding: 2em;
  display: flex;
}

.attention {
  text-align: start;
  background-color: #EAF0FF;
  color: #6283D7;
  font-size: 1em;
  padding: 0.375rem;
  margin-bottom: 3%;
}

.radio {
  display: inline-flex;
  overflow: hidden;
  border-radius: 8px;
  box-shadow: 0 0 5px rgba(0, 0, 0, 0.25);
}

.radio__input {
  margin-right: -9%;
  display: none;

}

.radio__label {
  padding: 1rem 1rem;
  position: relative;
  display: inline-block;
  color: blue;
  transition: 0.5s;
  user-select: none;
  overflow: hidden;
  text-align: center;
  margin: auto;



}

.radio__label:not(:last-of-type) {
  border-right: 1px solid #7E93CA;
}

.radio__input:checked+.radio__label {
  color: blue;
  box-shadow: 0 2px 20px #6283D7;
}

.envoyer {
  width: 100% !important;
  margin-left: 3%;

}

.ajoute {
  margin-left: auto;
  width: 30% !important;
  height: auto;
}

.file_card {
  background-color: #EBF0FF;
  border: 1px dashed black;
  border-radius: 7px;
  padding: 0.9em;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  transition: background-color 0.4s;
}



.file_inputs {
  position: relative;
  margin-bottom: 1em;
}

.file_inputs button:hover {
  background: none;
}

.file_change {
  position: relative;
  max-width: 200px;
  height: 40px;
  z-index: 2;
  cursor: pointer;
  opacity: 0;
}


.bye {
  position: absolute;
  top: 0px;
  left: 0px;
  background: none;
  border: none;
  width: 100%;
  align-items: start;
  justify-content: start;
  display: flex;
  cursor: pointer;


}



.file_card:hover {
  background-color: #EBF0FF;
}

.JPG {
  font-size: 0.8em;
  color: gray;
  cursor: pointer;
}

.cliquez {
  font-size: 0.88rem;
  color: #6283D7;
  cursor: pointer;
}

.file_card,
.file_card * {
  cursor: pointer !important;
}

.stickyTop {
  position: sticky;
  top: 0;
  z-index: 2;
  /* Bootstrap's z-index for fixed components */
}