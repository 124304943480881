.filterBtn {
    background-color: #0E4458;
    color: white;
}

.filterBtn:hover,
.filterBtn:active {
    background-color: #12556e !important;
    color: white !important;
}

.searchBtn {
    background-color: #0F006F;
    color: white;
}

.searchBtn:hover,
.searchBtn:active {
    background-color: #13008b !important;
    color: white !important;
}