* {
    box-sizing: border-box;
}

.FromBox {
    text-align: center;
    background-color: #fff;
    border: 1px solid rgba(0, 0, 0, 0.125);
    border-radius: 1rem;
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.2);
    margin: 3%;
    padding: 2em;

}

.FromBox input {
    margin: 0.8em 0em;

}

.incrtiptionimage {
    border-radius: 0 100px 100px 0;
    max-height: 100%;
}

.Boxhead {
    padding: 0.75rem 1.25rem;
    text-align: center;
}


.attention {
    text-align: start;
    background-color: #EAF0FF;
    color: #6283D7;
    font-size: 1em;
    padding: 0.375rem;
    margin-bottom: 3%;
}

.inscris {
    margin: auto;
    width: 40%;
}

button:hover {
    background-color: #0056b3;
}

.email_input {
    transition: border-color 0.3s ease;
}

.shake {
    animation: shake 0.3s ease-in-out;
    border-color: red;
}

.pointer {
    cursor: pointer;
}

@keyframes shake {
    0% {
        transform: translateX(0);
    }

    25% {
        transform: translateX(-5px);
    }

    50% {
        transform: translateX(5px);
    }

    75% {
        transform: translateX(-5px);
    }

    100% {
        transform: translateX(0);
    }
}