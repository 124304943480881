* {
    box-sizing: border-box;
}

.body {
    background-color: #F8F8F7;
}

form {
    display: flex;
    flex-direction: column;
}

.button_container {
    width: 100%;
    border-top-left-radius: 2.2em;
    border-top-right-radius: 2.2em;
}

.button_container button {
    border-width: 0px !important;
}

.title {
    line-height: 100%;
    color: #002C99;
    font-size: 7EM;
    font-weight: 900;
}

.button_container button {
    width: 50%;
    margin-left: auto;
    border-radius: 1em;
    height: AUTO;
    text-wrap: nowrap;
    border-color: rgba(126, 147, 202, 0.5);
    border-width: 2px;
}

.button_container {
    position: relative;
    z-index: 2;
}

.mark {
    width: 30%;
    margin: auto;
}

.container {
    top: 55%;
    width: 100%
}

.input_container {
    display: flex;
    flex-direction: column;
    align-items: start;

}

.input_container input {
    width: 100%;
}

.inputs_container {
    display: flex;
    flex-direction: row;
    justify-content: space-evenly;
    width: 100%;
}


.form_container {
    background-color: white !important;
    box-shadow: 4px 4px 16px 8px rgba(0, 0, 0, 0.25);

    border-bottom-left-radius: 1em;
    border-bottom-right-radius: 1em;
    border-color: rgba(126, 147, 202, 0.5);
    border-width: 2px solid;
    width: 100%;
}

.p1 {
    display: flex;
}

.p11 {
    width: 60%;
}

.p12 {
    position: relative;
    display: flex;
    justify-content: center;
    align-items: center;
}

.p12 svg {
    position: absolute;
}

.p12 img {
    position: relative;
    width: 600px;
    z-index: 2;
    margin: auto;
}

.carouselHeight {
    position: "absolute";
    top: 10px;
}

.carouselHeight,
.carouselHeight img {
    height: 70vh;
    width: 100%;
}

.carouselHeight img {
    object-fit: cover;
    object-position: center;
}

.form_button {
    background-color: #d8a942 !important;
    border: none !important;
    color: white !important;
    font-weight: bold !important;
    height: 42px;
}

.form_button:hover,
.form_button:focus,
.form_button:active {
    background-color: rgb(255, 179, 15) !important;
    border: none !important;
}

.containerTop {
    top: 30%;
    text-align: center;
    color: wheat;
}

.t1 {
    color: #1f406d;
}

.t2 {
    color: #d8a942;
}

.firstContainer {
    height: 70vh;
}

.secondContainer {
    width: 100%;
    min-height: 70vh;
}

.secondContainerBg {
    position: absolute;
    height: 50%;
    top: 25%;
    background-color: #2D7CCA;
    width: 75%;
}

.thirdContainer {
    /** background: #0000 linear-gradient(270deg, #dabf87, rgba(121, 78, 78, 0.2)) 0 0 no-repeat padding-box*/
    background-image: url("../../assets/container3.jpg");
    background-size: cover;
}

.thirdContainer div {
    /** background: #0000 linear-gradient(270deg, #dabf87, rgba(121, 78, 78, 0.2)) 0 0 no-repeat padding-box*/
    background-color: rgba(11, 72, 90, 0.65);
    border-radius: 45px;
    color: white;
}

.forthContainer {
    background-image: url("../../assets/container4.jpg");
    background-size: cover;
}

.card {
    background-color: white;
}

.card:hover {
    background-color: #F2F2F2;
    cursor: pointer;
}

.ccbuttonsContainer {
    height: 100% !important;
}

.ccbutton1 {
    height: 33.3% !important;
    clip-path: polygon(0 0, 76% 0%, 84% 50%, 92% 100%, 0 100%);
    background-color: #0B0935;
}

.ccbutton2 {
    height: 33.3% !important;
    clip-path: polygon(0 0, 92% 0%, 100% 50%, 92% 100%, 0 100%);
    background-color: #0B0935;
}

.ccbutton3 {
    height: 33.3% !important;
    clip-path: polygon(0 0, 92% 0%, 84% 50%, 76% 100%, 0 100%);
    background-color: #0B0935;
    /* Change this to your desired color */
}

.ccbutton1 a,
.ccbutton2 a,
.ccbutton3 a {
    height: 100% !important;
    width: 100% !important;
}


.ccsvg {
    width: 90%;
}

.collapseHeading {
    position: relative;
}

.arrowIcon {
    transition: transform 0.3s ease;
    display: inline-block;
}

.collapseHeading[aria-expanded="true"] .arrowIcon {
    transform: rotate(180deg);
}

.mapBtn {
    width: 90%;
}



.secondCube {
    width: 30% !important;
    height: 20%;
    top: 75%;
    right: 0;
    background-color: #6EC4D8;
    z-index: 1;
}



.ccmc {
    text-align: center;
}

.imgContainer {
    padding-left: 20px;
    position: relative;
    flex-shrink: 0;
    /* Prevent the container from shrinking */
    width: 100%;
    /* Adjust the width of the container */
    max-width: 400px;
    /* Set a maximum width for the container */
    overflow: hidden;
    /* Ensure content stays within the container */
    margin: 0;
}

.ccmcImg {
    width: 100%;
    /* Ensure the image fits the container */
    height: auto;
    /* Maintain aspect ratio */
    object-fit: cover;
    /* Adjust the fit */
}

.filter {
    position: absolute;
    top: 0;
    left: 20px;
    right: 0;
    bottom: 0;
    background-color: rgba(37, 29, 255, 0.36);
    opacity: 0.53;
    pointer-events: none;
}

.ccmccc {
    position: relative;
    right: 20px;
    margin-top: 40px
}

.ccmcc {
    position: relative;
    bottom: 30px;
    top: auto;
    left: 20px;
    max-width: calc(100% - 20px) !important;
    /* Ensure the width does not exceed the viewport width */
    box-sizing: border-box;
    /* Include padding and border in the element's total width */

    /* Hide any overflowing content */
}

.ourAdventage {
    margin-top: auto;
    margin-bottom: 0;
}

.ourAdventage1,
.ourAdventage {
    font-family: "Rockwell" !important;
    font-weight: bold;
    margin-left: 7%;
    color: #0A0733;
}


.adventages {
    position: relative;
}

.leftBox {
    position: absolute;
    z-index: 0;
    width: 25%;
    margin: auto;
    height: 70%;
    bottom: 0;
    align-self: center;
    background-color: #EDD8A9;
    opacity: 0.72;
    color: white;
    padding: 20px;
    border-radius: 0;
    margin: 0;
}

.svg {
    width: 50px;
    height: 50px;
}

.ccmcText {
    font-size: 15px;
}

.ccmcText1 {
    font-size: 20px;
}

@media screen and (min-width:576px) {
    .mapBtn {
        width: auto;
    }
}



@media screen and (max-width:767px) {

    .ccsvg {
        width: 100%;
    }

    .ccmccCollapse {
        width: calc(100vw - 40px) !important;
    }
}

@media screen and (max-width:992px) {
    .ccmc1 {
        background-color: white;
        box-shadow: 0px 3px 6px rgba(0, 0, 0, 0.16);
    }

    .ccbutton1,
    .ccbutton2 {
        clip-path: none;
        background-color: #0B0935;
    }

    .ccbutton3 {
        height: 33.3% !important;
        clip-path: polygon(100% 0%, 100% 80%, 50% 100%, 0% 80%, 0 0);
        background-color: #0B0935;
        /* Change this to your desired color */
    }

    .ccbutton1 a,
    .ccbutton2 a,
    .ccbutton3 a {
        text-align: center !important;
    }

    .collapseContainer {
        margin-left: 20px !important;
    }
}

@media screen and (min-width:768px) {

    .collapseContainer {
        margin-left: 0px !important;
    }


}

@media screen and (min-width:992px) {
    .svg {
        width: 100px;
        height: 100px;
    }

    .ccmccCollapse {
        width: calc(100vw - 440px) !important;
        box-shadow: 3px 9px 9px rgba(0, 0, 0, 0.16);
    }

    .secondCube {
        width: 20% !important;
        height: 60%;
        top: 40%;
        right: 0;
        background-color: #6EC4D8;
        z-index: 1;
    }

    .aboutUsRotated {
        position: relative;
    }
}

@media screen and (min-width:1200px) {
    .ccmcText {
        font-size: 24px;
    }
}

.aboutUs {
    display: flex;
    flex-wrap: wrap;
    min-height: 100vh;
    /* Ensures full height for large screens */
}

.aboutUsSvg {
    border: 1px solid #F4D193;

}

.aboutUsSvg img {
    width: 100%;
    height: 100%;
}

.aboutUsRotated {
    transform-origin: top left;
    transform: rotate(270deg) translate(-100%, 0);
    position: absolute;
    top: 0;
    left: 0;
}

.aboutUs1 {
    display: flex;
    align-items: center;
    /* Centers content vertically */
    justify-content: end;
    /* Centers content horizontally */
    flex: 1;
    /* Ensures equal height with second column */
    position: relative;
}

.aboutUs11 {
    background-color: #183C52;
    width: 100%;
    /* Optional: Restricts max width for better readability */
    margin: 0;
    /* Centers the content horizontally */
    padding: 20px;
    height: 90%;
    /* Adjusts dynamically to fit content */
}

.aboutUs2 {
    display: flex;
    align-items: center;
    /* Centers content vertically */
    justify-content: center;
    /* Centers content horizontally */
    flex: 1;
    position: relative;
    background-image: url("../../assets/aboutUs.png");
    background-size: cover;
    background-position: center;
    padding: 20px;
    /* Adds padding for better spacing */
}

.aboutUs21 {
    position: absolute;
    /* Keeps the offset */
    left: -20px;
    /* Preserves the specified left offset */
    width: 100%;
    text-align: center;
    /* Centers content */
}

.cardShadow {
    position: absolute;
    top: -18px;
    /* Offset to the top */
    left: -18px;
    width: 100%;
    /* Offset to the left */

    /* Match the width of the card */
    height: 90%;
    /* Match the height of the card */
    background-color: #F2E3C1;
    /* Shadow color */
    z-index: 0;
    /* Make sure it is beneath the card */
    border-radius: 0;
    /* Match the card's border-radius */
}

.carouselContainer {
    width: 90%;
    /* Set the width of the carousel */
    margin: 0 auto;
    margin-top: 30px;
    overflow-x: auto;
    /* Allow horizontal scrolling */
    cursor: grab;
    /* Show the grab cursor */
}

.carousel {
    display: flex;
    flex-wrap: nowrap;
    /* Prevent wrapping of items */
    transition: transform 0.2s ease-in-out;
    /* Smooth scroll */
}

.carouselItem {
    min-width: 300px;
    /* Width of each item */
    margin-right: 10px;
    /* Space between items */
}

.carouselItem img {
    width: 300px;
    height: 300px;
    object-fit: cover;
    border-radius: 0px;
    box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
}

.carouselContainer::-webkit-scrollbar {
    display: none;
}

.carouselText {
    position: absolute;
    bottom: 10px;
    /* Position from the bottom */
    right: 10px;
    /* Position from the right */
    z-index: 10;
    /* Ensure it stays above the image */
}

.down {
    /* margin-top: 150px;*/
}

@media (max-width: 768px) {
    .aboutUs {
        min-height: auto;
        /* Removes full height for smaller screens */
        flex-direction: column;
        /* Stacks columns vertically */
    }

    .aboutUs1,
    .aboutUs2 {
        flex: none;
        width: 100%;
        /* Makes columns take full width */
        padding: 10px;
        /* Adjust padding for smaller screens */
    }

    .aboutUs11 {
        height: auto;
        /* Adjust height to fit content */
    }

    .aboutUs21 {
        position: relative;
        /* Allows natural flow for small screens */
        left: 0;
        /* Resets offset for smaller screens */
        padding: 10px;
    }

    .aboutUsRotated {
        transform: rotate(270deg) translate(-100%, -100%);
    }
}