.card:hover {
    background-color: #F2F2F2;
    cursor: pointer;
}

.container {
    margin-top: 18px !important;
}

.container * {
    font-family: "Rounded Elegance" !important;

}

.title {
    font-family: "Sakkal Majalla" !important;
    font-weight: bold;
}

.cardShadow {
    position: absolute;
    top: -18px;
    /* Offset to the top */
    left: -18px;
    width: calc(100% + 18px);
    /* Offset to the left */
    ;
    /* Match the width of the card */
    height: 90%;
    /* Match the height of the card */
    background-color: #F2E3C1;
    /* Shadow color */
    z-index: 0;
    /* Make sure it is beneath the card */
    border-radius: 0;
    /* Match the card's border-radius */
}

.card {
    background-color: white;
}