.navs button>h5,
.navs button.active>h5 {
    color: rgba(34, 42, 66, 0.7) !important;
}

.navs button:hover:not(:active)>h5 {
    color: rgba(23, 24, 26, 0.7) !important;
}

.navs button:hover:not(:focus)>h5 {
    color: rgba(23, 24, 26, 0.7) !important;
}


.roundedButtonsContainer button {
    background-color: #f5f5f5;
    border: 1px solid #f5f5f5;
    border-radius: 50%;
    transition: all 0.3s;
    width: 45px !important;
    /* Set the width to a fixed value */
    height: 45px !important;
    display: flex;
    justify-content: center;
    align-items: center;
}

.roundedButtonsContainer button:hover svg path {
    fill: white !important;
    font-size: 20px;
}

/*
.navs button.active h5 {
    color: white !important;
}*/