.sidebar {
    height: 100%;
    overflow: auto;
    background-color: white;
}

.card {
    box-shadow: 0px 3px 6px rgb(216, 168, 66, 1);
}

.card:hover {
    background-color: #F2F2F2;
    cursor: pointer;

}

.form_button {
    background-color: #D8A842 !important;
    border: none !important;
    color: white !important;
    font-weight: bold !important;
    height: 42px;
}

.form_button:hover,
.form_button:focus,
.form_button:active {
    background-color: rgb(224, 175, 70) !important;
    border: none !important;
}

.filter_button {
    height: 42px;
}

.card:hover {
    background-color: #F2F2F2;
    cursor: pointer;
}

.filterContainer {
    box-shadow: 0px 3px 6px rgb(14, 68, 88, 1) !important;
}

.stickyTopFilter {
    position: sticky;
    top: var(--navbar-height);
    /* Default 60px if the variable is not set */
    z-index: 1019;
    background-color: white;
    padding: 10px;
}

.sideFilterOffcanvas {
    padding-top: var(--navbar-height) !important;
}

.stickySideFilter {
    position: sticky;
    top: calc(var(--navbar-height) + var(--trajetTopFilter-height) + 1%);
    overflow-y: auto;
    z-index: 1018;
}

@media screen and (min-width: 992px) {
    .stickyTopFilter {
        padding: 10px 10%;
    }

}