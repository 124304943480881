.img {
    width: 100%;
    height: 4px;
    /* Ensure the SVG fills the container */
    object-fit: cover !important;
    /* Cut to fit the container */
    object-position: center;
    overflow: hidden;
    /* Ensure the SVG is cut to fit the width */
}

.tableContainer {
    max-width: 100%;
    overflow-x: auto;
}