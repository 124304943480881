/* Fixed Container for chat */
.fixedContainer {
    bottom: 0;
    right: 0;
    width: 100%;
    z-index: 1065;
    pointer-events: none;
    /* Allow clicks to pass through */
}

/* Chat Box Container */
.chatBoxContainer {
    margin-right: 10px;
    pointer-events: all;
}

/* Chat Ball Container */
.chatBallContainer {
    display: flex;
    gap: 10px;
    flex-direction: column;
    justify-content: end;
    pointer-events: all;
}

/* Chat Ball Style */
.chatBall {
    background-color: #3f5064;
    height: 70px;
    width: 70px;
    border-radius: 50%;
    display: flex;
    align-items: center;
    justify-content: center;
}

/* Chat Ball Text */
.chatBallText {
    text-align: center;
    font-size: 24px;
    color: white;
    margin: 0;
}

/* Chat Box Container */
.chatContainer {
    max-width: 350px;
    min-width: 200px;
    margin: auto;
    margin-bottom: 0px;
}

/* Card Style */
.chatCard {
    border-radius: 0;
}

/* Chat Header */
.chatHeader {
    background-color: #3f5064;
    color: white;
    border-color: #3f5064;
    padding: 1rem;
    cursor: pointer;
}

/* User Name */
.chatUser {
    font-weight: bold;
    margin: 0;
}

/* Close Chat Button */
.btnCloseChat {
    background: transparent;
    border: none;
    padding: 0;
}

/* Chat Body */
.chatBody {
    padding: 10px;
}

/* Chat Messages */
.chatMessages {
    height: 250px;
    overflow-y: auto;
    display: flex;
    flex-direction: column;
}

/* Avatar */
.chatAvatar {
    width: 40px;
    height: 40px;
    border-radius: 50%;
    margin-left: 10px;
}

/* Message Bubble */
.senderMessage,
.receiverMessage {
    display: flex;
    align-items: center;
}

.messageBubble {
    padding: 10px;
    border-radius: 15px;
    max-width: 75%;
    word-wrap: break-word;
    word-break: break-word;
    white-space: normal;
}

.senderBubble {
    background-color: #fbfbfb;
    margin-right: 10px;
}

.receiverBubble {
    background-color: rgba(57, 192, 237, 0.2);
    margin-left: 10px;
}

/* Message Input Form */
.chatInputForm {
    margin-top: 10px;
}

/* Send Button */
.sendButton {
    background-color: #3f5064;
}

.sendButton svg {
    fill: white;
}

.sendButton:hover {
    background-color: #2e3a4d;
}

/* Media Queries for Responsiveness */
@media (max-width: 768px) {

    /* For smaller screens (mobile devices) */
    .chatCard {
        width: 100%;
        /* Make chat container take up the full width */
        margin: 0 10px;
    }

    .chatContainer {
        max-width: 100%;
        /* Ensure chat container is 100% width */
        margin-bottom: 20px;
    }

    .chatMessages {
        height: auto;
        /* Allow message list to expand dynamically */
        max-height: 60vh;
        /* Limit the height for mobile screens */
    }

    .chatAvatar {
        width: 35px;
        /* Reduce avatar size on small screens */
        height: 35px;
    }

    .chatBall {
        height: 50px;
        width: 50px;
    }

    .chatBallText {
        font-size: 18px;
    }

    .sendButton {
        padding: 5px 10px;
        /* Adjust send button for smaller screens */
    }
}

/* New media query for max-width: 420px */
@media (max-width: 420px) {

    .chatBallContainer .chat_ball:not(:last-child) {
        display: none;
        /* Hide all chat balls except the last one */
    }
}

@media (min-width: 769px) {

    /* For larger screens (tablets, desktops) */
    .chatCard {
        width: 350px;
        /* Fixed width for larger screens */
    }

    .chatBallContainer {
        bottom: 20px;
        right: 20px;
    }
}

.chatHeaderLink {
    text-decoration: none;
    display: inline-block;
    /* Make sure the <a> element respects width and padding */
}

.chatHeaderLink:hover {
    box-shadow: inset 0 -1px 0 0 #fff;
    /* Create a white underline effect */
}

.chatHeaderLink svg {
    vertical-align: middle;
}